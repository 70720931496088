import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const monitoringChartsPlugin = createPlugin({
  id: 'monitoring-charts',
  routes: {
    root: rootRouteRef,
  },
});

export const MonitoringChartsPage = monitoringChartsPlugin.provide(
  createRoutableExtension({
    name: 'MonitoringChartsPage',
    component: () => import('./MyPluginRoot').then(m => m.default),
    mountPoint: rootRouteRef,
  }),
);
